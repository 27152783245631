<template>
  <div class="paginator-container">
    <div
      class="paginator-page paginator-page--back"
      :class="{ 'paginator-page--active': first }"
      @click="onClickBack">
      <ArrowLeft :color="first ? '#CCCCCC' : '#009688'" />
    </div>
    <div
      v-for="(page, index) in pages"
      :key="index"
      class="paginator-page"
      :class="{ 'paginator-page--active': index === current }"
      @click="onClickPage(index)">
      {{ page }}
    </div>
    <div
      class="paginator-page paginator-page--forward"
      :class="{ 'paginator-page--active': last }"
      @click="onClickForward">
      <ArrowRight :color="last ? '#CCCCCC' : '#009688'" />
    </div>
  </div>
</template>

<script>
import ArrowLeft from '@/components/svg/ArrowLeft'
import ArrowRight from '@/components/svg/ArrowRight'

export default {
  components: {
    ArrowLeft,
    ArrowRight
  },
  props: {
    total: {
      type: Number,
      required: true
    },
    limit: {
      type: Number,
      required: true
    },
    offset: {
      type: Number,
      required: true
    }
  },
  computed: {
    pages () {
      return Math.ceil(this.total / this.limit)
    },
    current () {
      return Math.ceil(this.offset / this.limit)
    },
    first () {
      return this.current === 0
    },
    last () {
      return this.current === this.pages - 1
    }
  },
  methods: {
    onClickPage (page) {
      this.$emit('paginate', page)
    },
    onClickBack () {
      if (!this.first) {
        this.$emit('paginate', this.current - 1)
      }
    },
    onClickForward () {
      if (!this.last) {
        this.$emit('paginate', this.current + 1)
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/variables.scss";

.paginator {
  &-container {
    display: flex;
  }

  &-page {
    color: $core-teal-500;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-top: 1px solid $grey-300;
    border-bottom: 1px solid $grey-300;
    border-left: 1px solid $grey-300;
    transition: 0.2s all ease-in-out;

    &--back {
      border-radius: 5px 0 0 5px;
    }

    &--forward {
      border-right: 1px solid $grey-300;
      border-radius: 0 5px 5px 0;
    }

    &--active {
      color: $grey-300;
    }

    &:hover:not(&--active) {
      background: $core-teal-100;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
    }
  }
}
</style>
