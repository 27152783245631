<template>
  <Modal
    v-if="loadingOffers || loadingServiceProviders"
    position="center"
    :closable="false"
    variant="info"
    class="mb-5">
    <template>
      <LoadingSpinner />
    </template>
  </Modal>
  <Modal
    v-else-if="exceptionServiceProviders"
    position="center"
    :closable="false"
    variant="warning"
    class="mb-5">
    <template>
      <h3>Något gick fel</h3>
    </template>
  </Modal>
  <div v-else>
    <PageBanner
      heading="Tjänster du kan beställa"
      subheading="Här kan du se och jämföra alla tjänster som kan levereras till din adress" />

    <PageBody
      class="page">
      <div
        v-if="selectedAddress"
        class="selected-address-container">
        <div class="selected-address">
          {{ selectedAddress.streetName | capitalizeFirstLetter }} {{ selectedAddress.streetNumber }}, {{ selectedAddress.city | capitalizeFirstLetter }}
          <div
            v-if="!isClosed"
            class="selected-address__close-button"
            @click="resetAddressState">
            <img
              alt="Clear address"
              src="@/assets/svg/close_selected_address.svg"
              width="15"
              height="15">
          </div>
        </div>
      </div>

      <ConnectedServicesModal
        v-show="!isWarningModalClosed"
        :closable="true"
        @close="isWarningModalClosed = true" />

      <div class="main-container">
        <OffersFilter
          :offers="offers"
          :filters="filters"
          :show-mobile-filter="showMobileFilter"
          @closeMobileFilter="showMobileFilter = false"
          @filterUpdate="updateFilters" />

        <div class="offer-list">
          <div class="offer-list__head">
            <div class="offer-list__head__info">
              <p>
                {{ infoText }}
              </p>
              <ActionButton
                v-show="!showMobileFilter"
                class="offer-list__head__filter-button"
                :class="{ 'offer-list__head__filter-button--bold': activeFiltersCount > 0 }"
                :text="mobileFilterText"
                icon="filter"
                @click="showMobileFilter = true" />
            </div>

            <p class="offer-list__head__sorting-label label">
              Sortering
            </p>
            <Dropdown
              id="dropdown"
              class="offer-list__head__sorting-dropdown"
              :placeholder="initialSortingLabel"
              :options="sortOptions"
              @onChange="onChangeSorting" />
          </div>

          <Modal
            v-show="!allFilteredOffers.length"
            class="no-offers-modal"
            heading="Inga erbjudanden"
            position="center"
            variant="info"
            :closable="false">
            <template>
              <p>Det finns inga erbjudanden med aktuella filterinställningar</p>
            </template>
          </Modal>

          <div class="offer-list__items">
            <OfferCards
              :offers="paginatedFilteredOffers"
              :service-providers="serviceProviders.items"
              :is-closed="isClosed"
              @onOfferClick="handleOfferClick" />
            <div
              v-if="allFilteredOffers.length"
              class="offer-list__items-pagination">
              <Paginator
                :total="allFilteredOffers.length"
                :limit="pagination.limit"
                :offset="pagination.offset"
                @paginate="onPaginate" />
            </div>
          </div>
        </div>
      </div>
    </PageBody>
  </div>
</template>

<script>
import {
  mapState,
  mapActions,
  mapGetters
} from 'vuex'
import LoadingSpinner from '@/components/LoadingSpinner'
import PageBanner from '@/components/views/PageBanner'
import PageBody from '@/components/views/PageBody'
import Dropdown from '@/components/form-components/Dropdown'
import OfferCards from '@/components/available-offers/OfferCards'
import Modal from '@/components/modal/Modal'
import ConnectedServicesModal from '@/components/ConnectedServicesModal'
import ActionButton from '@/components/buttons/ActionButton'
import OffersFilter from '@/components/available-offers/OffersFilter'
import Paginator from '@/components/Paginator'
import { sortOptions } from '@/utils/offers.utils'
import { scrollTo } from '@/utils/window.utils'
import { RouteName } from '@/router/router-constants'

export default {
  components: {
    ActionButton,
    Modal,
    ConnectedServicesModal,
    PageBanner,
    PageBody,
    Dropdown,
    OfferCards,
    OffersFilter,
    Paginator,
    LoadingSpinner
  },
  filters: {
    capitalizeFirstLetter (text) {
      if (text) {
        const lowerCaseText = text.toLowerCase()
        return lowerCaseText.charAt(0).toUpperCase() + lowerCaseText.slice(1)
      }
      return text
    }
  },
  props: {
    isClosed: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      sortOptions,
      showMobileFilter: false,
      isWarningModalClosed: false
    }
  },
  computed: {
    ...mapState('AvailableOffers', [
      'exception',
      'sortBy',
      'filters',
      'loadingOffers',
      'pagination'
    ]),
    ...mapState('ServiceProviders', {
      serviceProviders: 'content',
      loadingServiceProviders: 'loading',
      exceptionServiceProviders: 'exception'
    }),
    ...mapGetters('AvailableOffers', [
      'allFilteredOffers',
      'paginatedFilteredOffers',
      'offers',
      'connectedServices'
    ]),
    ...mapGetters('AddressSearch', ['selectedAddress']),
    initialSortingLabel () {
      return this.sortOptions.find(option => option.value === this.sortBy).label
    },
    infoText () {
      const ending = this.allFilteredOffers.length > 1 ? 'erbjudanden' : 'erbjudande'
      return `Visar ${this.allFilteredOffers.length} ${ending}`
    },
    activeFiltersCount () {
      let filtersCount = []

      Object.keys(this.filters).forEach(filter => {
        this.filters[filter].forEach(() => filtersCount++)
      })

      return filtersCount
    },
    mobileFilterText () {
      return this.activeFiltersCount > 0 ? `Filtrera (${this.activeFiltersCount})` : 'Filtrera'
    }
  },
  watch: {
    allFilteredOffers () {
      this.setPaginationOffset(0)
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (from.name !== RouteName.closed.Offer) {
        vm.clearFilters()
        vm.resetSorting()
      }
    })
  },
  created () {
    this.fetchServiceProviders()
  },
  methods: {
    ...mapActions('AddressSearch', [
      'clearSelectedAddress',
      'clearAddresses',
      'clearCities',
      'setSelectedCity'
    ]),
    ...mapActions('ServiceProviders', { fetchServiceProviders: 'loadContent' }),
    ...mapActions('AvailableOffers', [
      'changeSorting',
      'clearFilters',
      'updateFilters',
      'resetSorting',
      'setSelectedOffer',
      'setPaginationOffset'
    ]),
    handleOfferClick (offer) {
      this.setSelectedOffer(offer)

      this.$router.push({ name: RouteName.closed.Offer })
    },
    resetAddressState () {
      this.setSelectedCity(null)
      this.clearAddresses()
      this.clearCities()
      this.clearFilters()
      this.resetSorting()
      this.isWarningModalClosed = false

      this.$router.push({ name: RouteName.public.AddressSearch })
    },
    onPaginate (page) {
      scrollTo(0)
      this.setPaginationOffset(page * this.pagination.limit)
    },
    onChangeSorting (val) {
      this.changeSorting(val)
      this.setPaginationOffset(0)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/main.scss";
@import "@/assets/scss/typeface.scss";

.page {
  position: relative;
  padding-top: 55px;
}

.address-search {
  margin-top: 40px;
  margin-bottom: 80px;
}

.selected-address-container {
  position: relative;
}

.selected-address {
  @include default-shadow;

  font-family: Roboto-Bold, Arial, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.5px;

  border-radius: 12px;
  background-color: $white;
  text-align: center;
  padding: 17px 0;
  position: absolute;
  top: -83px;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  @media(min-width: $breakpoint-lg) {
    width: 384px;
  }

  &__close-button {
    margin-left: 15px;
    margin-bottom: -2px;
    &:hover {
      cursor: pointer;
    }
  }
}

.main-container {
  display: flex;
  margin-right: 0;
  margin-left: 0;
  flex-direction: column;

  @media(min-width: $breakpoint-lg) {
    flex-direction: row;
  }
}

.offer-list {
  padding-right: 0;
  flex: 1;

  @media(min-width: $breakpoint-lg) {
    margin-left: 25px;
  }

  &__head {
    display: flex;
    flex-direction: column;

    @media(min-width: $breakpoint-lg) {
      flex-direction: row;
      align-items: center;
    }

    &__info {
      flex: auto;
      display: flex;
      justify-content: space-between;
    }

    &__sorting-label {
      display: none;

      @media(min-width: $breakpoint-lg) {
        margin-right: 25px;
        display: block;
      }
    }

    &__filter-button {
      @media(min-width: $breakpoint-lg) {
        display: none;
      }

      &--bold {
        font-family: Roboto-Bold, Arial, sans-serif !important;
      }
    }

    &__sorting-dropdown {
      width: 100%;

      @media(min-width: $breakpoint-lg) {
        width: 247px;
      }
    }
  }

  &__items {
    margin-top: 30px;

    &-pagination {
      margin-top: 30px;
      display: flex;
      justify-content: center;
    }
  }
}

.no-offers-modal {
  margin-top: 40px;
}
</style>
