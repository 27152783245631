export const scrollTo = (elementOffsetTop) => {
  window.scrollTo(
    {
      top: elementOffsetTop,
      behavior: 'smooth'
    }
  )
}

export const scrollToFirstInvalidField = (formComponent, formValidator) => {
  const invalidFields = Object.keys(formValidator.$params).filter(fieldName => formValidator[fieldName].$invalid)

  const formFields = formComponent.$refs
  const firstInvalidFieldOffsetTop = formFields[invalidFields[0]].$el.offsetTop

  scrollTo(firstInvalidFieldOffsetTop)
}
