<template>
  <ul class="offer-cards">
    <li
      v-for="(offer, key) of availableOffers"
      :key="key"
      class="offer-card"
      @click="onClick(offer)">
      <div class="offer-card__logo">
        <logo-container
          :logo-url="getOfferLogo(offer.serviceProvider.id)"
          :title="offer.friendlyName" />
      </div>

      <section class="offer-card__info">
        <h3 class="offer-card__title">
          {{ offer.friendlyName }}
        </h3>
        <p
          class="offer-card__description"
          v-html="offer.ingress" />
        <div class="offer-card__footer">
          <p class="offer-card__footer__pricing">
            <span>Pris från </span><span class="offer-card__footer__pricing__amount label">{{ offer.price }}</span><span> kr/månad</span>
          </p>
          <div class="offer-card__footer__button">
            <MainButton
              :expanded="true"
              label="Gå till erbjudandet" />
          </div>
        </div>
      </section>
    </li>
  </ul>
</template>

<script>
import MainButton from '@/components/MainButton'
import LogoContainer from '@/components/common/LogoContainer'

export default {
  components: {
    MainButton,
    LogoContainer
  },
  props: {
    offers: {
      type: Array,
      default: () => []
    },
    serviceProviders: {
      type: Array,
      default: () => []
    },
    isClosed: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    availableOffers () {
      const availableServiceProviderIds = this.serviceProviders.map(sp => sp.fields.id)
      return this.offers.filter(offer => availableServiceProviderIds.includes(offer.serviceProvider.id))
    }
  },
  methods: {
    getServiceProvider (id) {
      return this.serviceProviders.find(serviceProvider => serviceProvider.fields.id === id)
    },
    onClick (offer) {
      if (this.isClosed) {
        this.$emit('onOfferClick', offer)

      } else {
        const serviceProvider = this.getServiceProvider(offer.serviceProvider.id)

        window.location.href = offer.corporate && serviceProvider.fields.serviceProviderLinkCorporate
          ? serviceProvider.fields.serviceProviderLinkCorporate
          : serviceProvider.fields.serviceProviderLinkPrivate
      }
    },
    getOfferLogo (id) {
      return this.getServiceProvider(id) && this.getServiceProvider(id).fields.logo.fields.file.url
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/typeface.scss";
@import "@/assets/scss/main.scss";
@import "@/assets/scss/variables.scss";

.offer-cards {
  list-style: none;
  margin: 0;
  padding: 0;

  li:nth-child(2n+1) {
    background: $grey-100;
  }

  li {
    display: flex;
    flex-direction: column;
    padding-right: 26px;
    padding-left: 26px;
    padding-top: 17px;
    padding-bottom: 30px;
    transition: all 0.2s ease-in-out;

    @media(min-width: $breakpoint-md) {
      align-items: center;
      flex-direction: row;
      padding: 32px;
    }

    &:hover {
      background: $grey-200;
      cursor: pointer;
    }

    &:first-child {
      border-top: 1px solid $grey-200;
      border-bottom: 1px solid $grey-200;
    }

    &:not(:first-child) {
      border-bottom: 1px solid $grey-200;
    }
  }
}

.offer-card {
  &__info {
    width: 100%;
  }

  &__logo {
    @media(min-width: $breakpoint-md) {
      margin-right: 32px;
    }
  }

  &__title {
    font-weight: 700;
    margin-top: 17px;

    @media(min-width: $breakpoint-md) {
      margin: 0;
      font-size: 22px;
      font-weight: 400;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;

    @media(min-width: $breakpoint-md) {
      flex-direction: row;
      align-items: center;
    }

    &__pricing {
      flex: 1;

      &__amount {
        font-family: Helvetica, Arial, sans-serif;
        font-weight: 700;
        font-size: 22px;
      }
    }

    &__button {
      flex: 1;
    }
  }
}
</style>
