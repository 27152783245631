<template>
  <Modal
    v-if="connectedServices.length"
    class="connected-services-modal"
    position="left"
    variant="warning"
    :closable="closable"
    @close="$emit('close')">
    <template>
      <h3>Följande tjänster finns redan inkopplade på din adress</h3>
      <ul>
        <li
          v-for="(service, index) in connectedServices"
          :key="`services-${index}`"
          class="connected-services-modal__item">
          {{ service.offer.name }}
        </li>
      </ul>

      <p>Kontakta din leverantör om du vill ändra eller ersätta befintliga tjänster.</p>
    </template>
  </Modal>
</template>
<script>
import { mapGetters } from 'vuex'
import Modal from './modal/Modal'

export default {
  components: {
    Modal
  },
  props: {
    closable: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapGetters('AvailableOffers', ['connectedServices'])
  }
}
</script>

<style lang="scss" scoped>

.connected-services-modal {
  margin-bottom: 30px;

  &__item {
    font-weight: bold;
    margin: 30px 0;
  }
}
</style>
