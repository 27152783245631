<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16">
    <path
      class="transition"
      d="M7.7,7.693,1.675,13.712A.98.98,0,0,1,.288,12.326L5.618,7,.288,1.674A.98.98,0,1,1,1.675.288L7.7,6.307a.983.983,0,0,1,0,1.386Z"
      transform="translate(4 1)"
      :fill="color" />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#009688'
    }
  }
}
</script>

<style scoped lang="scss">
.transition {
  transition: 0.2s all ease-in-out;
}
</style>
