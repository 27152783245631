<template>
  <fieldset
    class="filter"
    :class="{'filter--mobile': showMobileFilter }">
    <div class="filter__head">
      <p class="filter__head__title title-200">
        Filter
      </p>
      <ActionButton
        class="filter__head__close-button"
        text="Dölj filter"
        icon="close"
        @click="$emit('closeMobileFilter')" />
    </div>

    <div class="filter__fieldset">
      <h3>
        Platform
      </h3>
      <Checkbox
        id="platform"
        v-model="platformFilter"
        :checked-value="PLATFORM_FILTER_KEY.BREDBAND"
        label="Bredband"
        @input="toggleBandwidthSpeedFilters" />
      <Checkbox
        v-for="(item, index) in availableSpeeds"
        :id="`speed-${index}`"
        :key="`speed-${index}`"
        v-model="bandwidthSpeedFilter"
        class="bandwith-speed-filter"
        :disabled="!isBandwidthSpeedFilterActive"
        :checked-value="item"
        :label="item.toString()" />

      <Checkbox
        id="tv"
        v-model="platformFilter"
        :checked-value="PLATFORM_FILTER_KEY.TV"
        label="Tv" />
      <Checkbox
        id="telefoni"
        v-model="platformFilter"
        :checked-value="PLATFORM_FILTER_KEY.TELEFONI"
        label="Telefoni" />

      <h3>
        Leverantörer
      </h3>
      <Checkbox
        v-for="(item, index) in providers"
        :id="`provider-${index}`"
        :key="`provider-${index}`"
        v-model="providerFilter"
        :checked-value="item"
        :label="item" />

      <MainButton
        class="filter-button"
        label="Nollställ filter"
        type="secondary"
        :expanded="true"
        @click="clearFilter" />
    </div>
  </fieldset>
</template>

<script>
import Checkbox from '@/components/form-components/Checkbox'
import ActionButton from '@/components/buttons/ActionButton'
import MainButton from '@/components/MainButton'

import { PLATFORM_FILTER_KEY } from '@/utils/offers.utils'

export default {
  components: {
    ActionButton,
    MainButton,
    Checkbox
  },
  props: {
    offers: {
      type: Array,
      required: true
    },
    filters: {
      type: Object,
      required: true
    },
    showMobileFilter: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      PLATFORM_FILTER_KEY,
      corporate: false,
      availableSpeeds: [],
      providers: []
    }
  },
  computed: {
    isBandwidthSpeedFilterActive () {
      return this.filters.platform.includes(PLATFORM_FILTER_KEY.BREDBAND)
    },
    platformFilter: {
      get: function () {
        return this.filters.platform
      },
      set: function (val) {
        this.$emit('filterUpdate', {
          ...this.filters,
          platform: val
        })
      }
    },
    bandwidthSpeedFilter: {
      get: function () {
        return this.filters.bandwidthSpeed
      },
      set: function (val) {
        this.$emit('filterUpdate', {
          ...this.filters,
          bandwidthSpeed: val
        })
      }
    },
    providerFilter: {
      get: function () {
        return this.filters.provider
      },
      set: function (val) {
        this.$emit('filterUpdate', {
          ...this.filters,
          provider: val
        })
      }
    }
  },
  mounted () {
    this.availableSpeeds = [... new Set(this.offers
      .map(offer => offer.downloadSpeedMBits))]
      .filter(speed => speed > 0)
      .sort((a, b) => a - b)

    this.providers = [... new Set(this.offers
      .map(offer => offer.serviceProvider.name))]
      .sort((a, b) => a.localeCompare(b))
  },
  methods: {
    toggleBandwidthSpeedFilters () {
      if (!this.platformFilter.includes(PLATFORM_FILTER_KEY.BREDBAND)) {
        this.$emit('filterUpdate', {
          ...this.filters,
          bandwidthSpeed: []
        })
      }
    },
    clearFilter () {
      this.$emit('filterUpdate', {
        platform: [],
        bandwidthSpeed: [],
        provider: []
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/typeface.scss";
@import "@/assets/scss/main.scss";
@import "@/assets/scss/variables.scss";

.filter {
  border: 1px solid $grey-200;
  padding: 32px;
  height: fit-content;
  display: none;

  @media(min-width: $breakpoint-lg) {
    display: block;
    min-width: 20%;
  }

  &__head {
    display: flex;
    justify-content: space-between;

    &__title {
      font-size: 22px;
      font-weight: 400;
      text-align: left;
      margin: 0;
    }

    &__close-button {
      @media(min-width: $breakpoint-md) {
        display: none;
      }
    }
  }

  &__fieldset {
    margin-top: 20px;

    ::v-deep .checkbox-input {
      margin-bottom: 20px;
    }
  }

  &--mobile {
    display: block ;
  }
}

.bandwith-speed-filter {
  margin-left: 20px;
}

.filter-button {
  margin-top: 20px;
}

</style>
